import io from "socket.io-client";
import SimplePeer from 'simple-peer';
import LocalPeer from "../localPeer";
import env from "../env";



export interface ChatMessage {
    userId: string;
    message: string;
    date: Date;
}

export class Peer {
    socketId: string;
    peerConnect: SimplePeer.Instance | null;
    name: string;
    // tracks: any[];
    stream?: MediaStream;
    speaking: boolean;
    // videoOutput: HTMLVideoElement;

    constructor(socketId: string, name: string) {
        this.socketId = socketId
        this.name = name
        this.speaking = false;
        this.peerConnect = null;

        // this.videoOutput = document.createElement("video")
        // this.videoOutput.classList.add("remote-video")
        // this.videoOutput.autoplay = true
        // this.videoOutput.muted = false;
        // this.videoOutput.volume = 1;
        // this.videoOutput.style.display = "none"
        // this.videoOutput.playsInline = true


        // const container = document.getElementsByClassName("video-container")[0]
        // container.appendChild(this.videoOutput)
    }

    startCall(localStream: MediaStream, socket: SocketIOClient.Socket, am_initiator: boolean, ice: RTCConfiguration, onStream: (stream: MediaStream) => void): void {
        this.peerConnect = new SimplePeer({
            initiator: am_initiator,
            stream: localStream,
            config: ice
        })
        this.peerConnect.on('signal', data => {
            // console.log("Need to signal ", this.socketId);
            socket.emit('signal', {
                signal: data,
                socket_id: this.socketId
            })
        })
        this.peerConnect.on('stream', stream => {
            console.log("Got stream from user", this.socketId);
            // this.videoOutput.srcObject = stream
            // this.videoOutput.id = this.socketId
            this.stream = stream;
            onStream(stream)
            // newVid.onclick = () => openPictureMode(newVid)
            // newVid.ontouchstart = (e) => openPictureMode(newVid)
            // videos.appendChild(newVid)
        })
    }

    signal(data: string | SimplePeer.SignalData): void {
        // console.log("got signal from", this.socketId); 
        this.peerConnect?.signal(data);
    }
}

type RemoteUser = { userId: string, name: string };
export interface CallState {
    isScreenSharing: boolean,
    ar: ARState | null
}

export interface ARState {
    from: string,
    to: string
}

export class Call {
    roomId: string;
    socket: SocketIOClient.Socket;
    peers: Peer[];
    newChatMessageHandler: (message: ChatMessage) => void;
    userName: string;
    localStream: MediaStream | null;
    camStream: MediaStream | null;
    activeUser: Peer | null;
    audioContext: AudioContext | null;
    localVideo: HTMLVideoElement;
    stateUpdate: () => void;
    arState: ARState | null;
    mediaStreamSource: MediaStreamAudioSourceNode | null;
    processor: ScriptProcessorNode | null;
    t: boolean;
    setLocalPeers: (peers: Peer[]) => void
    isScreenSharing: boolean;

    constraints: MediaStreamConstraints = {
        audio: true,
        video: {
            width: {
                max: 640
            },
            height: {
                max: 640
            },
            facingMode: {
                ideal: 'user'
            }
        }
    }

    iceConfig: RTCConfiguration = {
        iceServers: [
            {
                urls: "stun:stun.l.google.com:19302"
            },
            {
                urls: "stun:stun.appac.live:5349"
            },
            {
                urls: "turn:turn.appac.live:5349",
                credentialType: "password",
                credential: "somepassword",
                username: "guest"
            }
        ]
    };


    constructor(roomId: string, setLocalPeers: (peers: Peer[]) => void, stateUpdate: () => void, newChatMessageHandler: (message: ChatMessage) => void, localVideo: HTMLVideoElement) {
        this.setLocalPeers = setLocalPeers
        console.log("Starting call with room id: ", roomId)
        this.isScreenSharing = false;
        this.newChatMessageHandler = newChatMessageHandler;
        this.roomId = roomId;
        this.stateUpdate = stateUpdate;
        this.localVideo = localVideo;
        this.socket = io.connect(env.rtcBase, { path: "/rtc/socket.io/", transports: ['websocket'], rejectUnauthorized: false });
        this.peers = [];
        this.audioContext = null;
        this.mediaStreamSource = null;
        this.processor = null;
        this.t = false;
        this.arState = null;
        this.userName = "";

        this.localStream = null;
        this.camStream = null;
        this.activeUser = null;
        this.getCam();

        this.socket.on('initReceive', (socket_id: RemoteUser) => {
            console.log('INIT RECEIVE ' + socket_id, socket_id)
            // addPeer(socket_id, false)

            this.socket.emit('initSend', socket_id.userId)

            const user = this.peers.find(c => c.socketId === socket_id.userId)
            if (!user) { return }

            console.log("Answer connection with user ", user)
            if (!this.localStream) { return; }
            user.startCall(this.localStream, this.socket, false, this.iceConfig, (stream) => {
                console.log("T");
                setLocalPeers([...this.peers]);
            });

        })
        this.socket.on('initSend', (socket_id: string) => {
            console.log('INIT SEND ' + socket_id)
            // addPeer(socket_id, true)

            const user = this.peers.find(c => c.socketId === socket_id)
            if (!user) { return }

            console.log("Start connection with user ", user)
            if (!this.localStream) { return; }
            user.startCall(this.localStream, this.socket, true, this.iceConfig, (stream) => {
                console.log("T");
                setLocalPeers([...this.peers]);
            });
        })

        this.socket.on('chat-message', (data: ChatMessage) => {
            console.log("Got chat message", data)
            this.newChatMessageHandler(data)
        });

        this.socket.on("room-set-users", (data: { users: RemoteUser[] }) => {
            const users = data.users
            this.peers = []


            users.forEach((userData) => {
                const userId = userData.userId
                const userName = userData.name
                const user = new Peer(userId, userName)

                this.peers.push(user)
            })

            setLocalPeers([...this.peers]);
            console.log("Server Set Users: ", users)
        })

        this.socket.on("room-user-add", (data: RemoteUser) => {
            const userId = data.userId
            const userName = data.name

            const user = new Peer(userId, userName)
            this.peers.push(user)

            console.log("New User On Room", userId, userName)
            setLocalPeers([...this.peers]);
        })

        this.socket.on("room-show-user", async (data: RemoteUser) => {
            const userId = data.userId

            const user = this.peers.find(c => c.socketId == userId)
            if (!user) { return }

            // console.log("Show user ", user)
            this.showUser(user)
            this.setLocalPeers([...this.peers]);
        });
        this.socket.on('signal', (data: { socket_id: string, signal: string | SimplePeer.SignalData }) => {
            const userId = data.socket_id

            const user = this.peers.find(c => c.socketId == userId)
            if (!user) { return }

            user.signal(data.signal)
        })
        this.socket.on("set-ar", (data: { session: ARState }) => {
            this.arState = data.session
            this.stateUpdate()
        });
        this.socket.on("alert", (data: { error: string }) => {
            alert(data.error);
        });
        this.socket.on("room-user-name-change", (data: RemoteUser) => {
            const userId = data.userId
            const userName = data.name

            const user = this.peers.find(u => u.socketId === userId)
            if (!user) { return }

            user.name = userName
            console.log("User Name Change On Room: ", user)

            setLocalPeers([...this.peers]);
        });

        this.socket.on("room-user-remove", (data: RemoteUser) => {
            const userId = data.userId

            const user = this.peers.find(u => u.socketId === userId)
            if (!user) { return }
            user.peerConnect?.destroy();
            // user.videoOutput.remove()
            this.peers = this.peers.filter(u => u.socketId !== userId)

            console.log("Remove User From Room: ", userId)

            setLocalPeers([...this.peers]);
        })
    }
    disconnect(): void {
        this.socket.disconnect();
    }

    toggleMute(): boolean {
        if (!this.localStream) { return false; }
        for (const index in this.localStream.getAudioTracks()) {
            this.localStream.getAudioTracks()[index].enabled = !this.localStream.getAudioTracks()[index].enabled
            return this.localStream.getAudioTracks()[index].enabled;
        }
        return false;
    }

    toggleVideo(): boolean {
        if (!this.localStream) { return false; }
        for (const index in this.localStream.getVideoTracks()) {
            this.localStream.getVideoTracks()[index].enabled = !this.localStream.getVideoTracks()[index].enabled
            return this.localStream.getVideoTracks()[index].enabled;
        }
        return false;
    }

    getState(): CallState {
        return {
            isScreenSharing: this.isScreenSharing,
            ar: this.arState,
        }
    }

    startAR(toSocketId: string): void {
        this.socket.emit("set-ar", { to: toSocketId });
    }

    switchCamera(): void {

        if (typeof this.constraints.video !== "object") {
            return;
        }

        
        if (this.constraints.video.facingMode.ideal === 'user') {
            this.constraints.video.facingMode.ideal = 'environment'
        } else {
            this.constraints.video.facingMode.ideal = 'user'
        }



        const tracks = this.localStream.getTracks();

        tracks.forEach(function (track) {
            track.stop()
        })

        this.localVideo.srcObject = null
        navigator.mediaDevices.getUserMedia(this.constraints).then(stream => {

            this.peers.forEach(peer => {
                peer.peerConnect.streams[0].getTracks().forEach(track => {
                    stream.getTracks().forEach(screentrack => {
                        if (track.kind === screentrack.kind) {
                            peer.peerConnect.replaceTrack(track, screentrack, peer.peerConnect.streams[0]);
                        }
                    });
                });
            })

            this.localStream = stream
            this.localVideo.srcObject = this.localStream;
            // this.isScreenSharing = true;
            this.stateUpdate();
        })
    }

    getCam(): void {
        /**
         * UserMedia constraints
         */


        // enabling the camera at startup
        navigator.mediaDevices.getUserMedia(this.constraints).then(stream => {
            console.log('Received local stream');
            // const localVideo = document.getElementById("local-video") as HTMLVideoElement;
            this.localVideo.srcObject = stream;
            this.localVideo.muted = true;
            this.localStream = stream;
            this.camStream = stream;
            this.createAudioHandler();

            this.joinRoom()
        }).catch(e => { alert(`getusermedia error ${e.name}`); console.error(e); })


        // this.joinRoom()
    }

    public backToCamera(): void {
        this.localStream = this.camStream;
        this.localVideo.srcObject = this.localStream;

        this.peers.forEach(peer => {
            peer.peerConnect.streams[0].getTracks().forEach(track => {
                this.localStream.getTracks().forEach(screentrack => {
                    if (track.kind === screentrack.kind) {
                        peer.peerConnect.replaceTrack(track, screentrack, peer.peerConnect.streams[0]);
                    }
                });
            });
        })
    }

    private setStream(stream: MediaStream, ended: () => void): void {

        this.peers.forEach(peer => {
            peer.peerConnect.streams[0].getTracks().forEach(track => {
                stream.getTracks().forEach(stream => {
                    if (track.kind === stream.kind) {
                        peer.peerConnect.replaceTrack(track, stream, peer.peerConnect.streams[0]);
                    }
                });
            });
        })

        stream.getVideoTracks()[0].addEventListener('ended', () => {
            console.log('remove track');

            this.backToCamera();
            ended();
        });

        this.localStream = stream;

        this.localVideo.srcObject = this.localStream;
    }

    getScreenShare(): void {
        const oldStream = this.localStream;

        navigator.mediaDevices.getDisplayMedia().then(screenStream => {
            this.setStream(screenStream, () => {
                this.isScreenSharing = false;
                this.stateUpdate();
            });
            this.isScreenSharing = true;
            this.stateUpdate();
        })
    }

    streamCanvas(canvas: HTMLCanvasElement): void {

        const stream = canvas.captureStream(10);

        this.setStream(stream, () => {
            this.isScreenSharing = false;
            this.stateUpdate();
        });

        this.isScreenSharing = true;
        this.stateUpdate();
    }

    createAudioHandler(): void {
        if (!this.localStream) { return; }
        console.log("Start Audio handler");

        if (this.localStream.getAudioTracks().length === 0) {
            return
        }

        this.audioContext = new AudioContext();
        this.mediaStreamSource = this.audioContext.createMediaStreamSource(this.localStream);
        // this.mediaStreamSource.
        this.processor = this.audioContext.createScriptProcessor(2048, 1, 1);

        // this.mediaStreamSource.connect(this.audioContext.destination);
        this.mediaStreamSource.connect(this.processor);
        this.processor.connect(this.audioContext.destination);

        this.t = false;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const call = this
        this.processor.onaudioprocess = function (e) {
            const inputData = e.inputBuffer.getChannelData(0);
            const inputDataLength = inputData.length;
            let total = 0;

            for (let i = 0; i < inputDataLength; i++) {
                total += Math.abs(inputData[i++]);
            }

            const rms = Math.sqrt(total / inputDataLength);

            if (rms * 100 > 5) {
                if (!call.t) {
                    call.activateMe()
                    call.t = true;

                    setTimeout(() => {
                        call.t = false
                    }, 1000)
                }
            }
        };
    }
    showUser(user: Peer): void {
        if (this.activeUser !== user) {
            this.peers.forEach(u => {
                if (u.socketId !== user.socketId) { u.speaking = false }
            })

            user.speaking = true;
            // call.videoOutput.srcObject = user.stream
            this.activeUser = user
        }
    }

    activateMe(): void {
        // console.log("Activate Me");
        this.socket.emit("room-me", {})
    }
    sendChat(message: string): void {
        // console.log("Activate Me");
        this.socket.emit("chat-message", { message })
    }

    joinRoom(): void {
        console.log("Join Room", this.roomId)
        this.socket.emit("join-room", { roomId: this.roomId });
        this.stateUpdate();
    }

    setUserName(name: string): void {
        this.userName = name
        this.socket.emit("change-name", { name: name });
    }
}