import classNames from "classnames";
import PropTypes, { InferProps } from "prop-types";
import React, { DetailedHTMLProps, useEffect, useRef, VideoHTMLAttributes } from "react";
import FeatherIcon from 'feather-icons-react';
import LocalPeer from "../localPeer";
import { Call, Peer } from "../lib/call";
import { VolumeMute, VolumeHigh, Cube, PencilSharp, Close } from 'react-ionicons'

interface Props {
    className: string,
    call: Call,
    peer: Peer,
    selectedPeer: string,
    onPress: () => void,
    arEnabled: boolean,
    drawerStatus: boolean,
    changeDrawerStatus:  () => void
}

export default function PeerView(props: Props): JSX.Element {
    const { call, peer, className, arEnabled, selectedPeer,drawerStatus,changeDrawerStatus, onPress } = props;
    const videoRef = useRef<HTMLVideoElement>(null)

    const [isMuted, setIsMuted] = React.useState(false);

    useEffect(() => {
        console.log("PeerView started for ", peer.socketId);
        console.log(videoRef);
        if (videoRef.current && peer.stream) {
            videoRef.current.volume = 1
            videoRef.current.srcObject = peer.stream
        }
    }, [peer.stream])

    return (
        <div className={classNames('small-window-container', 'image', className, {"selected": selectedPeer === peer.socketId})}>
            <video ref={videoRef} onClick={onPress} autoPlay playsInline ></video>
            <div className="small-window-button small-window-button-left" onClick={() => { videoRef.current?.muted ? videoRef.current.muted = false : videoRef.current.muted = true; setIsMuted(videoRef.current.muted) }}>

                {videoRef.current?.muted ?
                    <VolumeMute color={'white'} />
                    :
                    <VolumeHigh color={'white'} />
                }</div>

            {arEnabled ?
                <div className="small-window-button small-window-button-right" onClick={() => { call.startAR(peer.socketId); }}>

                    <Cube color={'white'} />
                </div> : null}

                {arEnabled ?
                <div className="small-window-button small-window-button-right-bottom" onClick={ changeDrawerStatus }>

                    <PencilSharp color={'white'} />
                </div> :  null }
            <p className="small-window-title" onClick={onPress}>
                {peer.name ?? 'Anonymous'}
            </p>

        </div>

    )
}