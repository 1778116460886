import React from "react";
import PropTypes, { InferProps } from "prop-types";
import { Call, CallState, ChatMessage, Peer } from "../lib/call";
import LocalCall from "./localCall/localCall";
import PeerView from "./peerView";
import classNames from "classnames";
import UserChatMessage from "./userChatMessage";
import LocalPeer from "../localPeer";
import useSound from 'use-sound';
import { Recording, CloseCircleOutline, ArrowUndoSharp, VolumeMute, MicOffSharp, Pencil, Attach, SendSharp, VideocamOff, ArrowDown, ArrowUp, MicSharp, ChatbubblesSharp, DesktopSharp, Videocam, SettingsSharp, CallSharp, CameraReverse, Cube, Close } from 'react-ionicons'

import boopSfx from '../assets/notif.wav';
import boopSfx2 from '../assets/notif2.wav';
import VolumeControl from "./ParticipantsList";
import ParticipantChatMessage from "./participantChatMessage";
import SettingsModal from "./settingsModal";
import CanvasDraw from 'react-canvas-draw';
import { BsBrush, BsEraserFill, BsSquareFill } from 'react-icons/bs';
import { FaPaintBrush, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { SketchPicker } from 'react-color';
import SketchExample from "./sketchPicker";


declare global {
    interface Window { call: Call; }
}

type UserMessage = {
    imageLink: string,
    message: string,
    userName: string,
    time: string
}

type Color = {

    r: string,
    g: string,
    b: string,
    a: string


};

export default function CallManager({ roomId, userName }: { roomId: string, userName: string }) {

    const [hasCanvas, setHasCanvas] = React.useState(false);
    const [call, setCall] = React.useState<Call | null>(null);
    const [peers, setPeers] = React.useState<Peer[]>([]);
    const [messages, setMessages] = React.useState<ChatMessage[]>([]);
    const [newMessage, setNewMessage] = React.useState<ChatMessage | null>(null);
    const [callState, setCallState] = React.useState<CallState | null>(null);
    const [s, setS] = React.useState<boolean>(false);
    const [messageText, setMessageText] = React.useState('');
    const [selectedPeer, setSelectedPeer] = React.useState<null | string>(null);
    const localVideoRef = React.useRef<HTMLVideoElement>(null);
    const fullscreenVideoRef = React.useRef<HTMLVideoElement>(null);
    const arFullscreenVideoRef = React.useRef<HTMLVideoElement>(null);
    const [isUserCallUnMuted, setIsUserCallUnMuted] = React.useState<boolean>(true);
    const [showUserVideo, setShowUserVideo] = React.useState<boolean>(true);
    const [showSettings, setShowSettings] = React.useState<boolean>(false);
    const [muteMessageSound, setMuteMessageSound] = React.useState<boolean>(false)
    const [muteJoinedRoomSound, setMuteJoinedRoomSound] = React.useState<boolean>(false)
    const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0)
    const [username, setUsername] = React.useState<string>(userName);

    const canvasRef = React.useRef<CanvasDraw>(null);

    const [drawerEnabled, setDrawerEnabled] = React.useState<boolean>(false);
    const [showDrawingButtons, setShowDrawingButtons] = React.useState<boolean>(false);
    const [colorRGBA, setColorRGBA] = React.useState<Color>({
        r: '0',
        g: '0',
        b: '0',
        a: '1',
    })

    const changeColor = (newRGBA) => {

        return (
            setColorRGBA(newRGBA)

        )
    }
    const [play] = useSound(boopSfx, { volume: 0.5 });
    const [play2] = useSound(boopSfx2, { volume: 0.5 });

    React.useEffect(() => {
        if (newMessage) {
            setNewMessage(null);
            if (newMessage.userId === 'server') {
                if (!muteJoinedRoomSound) {
                    play2();
                }
            } else {
                if (!muteMessageSound) {
                    play();
                }
                if (!s) {
                    setUnreadMessageCount(unreadMessageCount + 1)
                }
            }

            setMessages([...messages, newMessage]);

        }

    }, [newMessage]);

    React.useEffect(() => {
        call?.setUserName(username);
    }, [username]);

    React.useEffect(() => {
        const call = new Call(roomId, setPeers, () => {
            console.log("State Update", call);
            setCallState(call.getState());
        }, setNewMessage, localVideoRef.current);
        window.call = call;
        call?.setUserName(username);
        setCall(call)
        return () => {
            call.disconnect();
        }
    }, [roomId])


    const sendButton = () => {
        //   const newMessage: UserMessage = {message:messageText, imageLink:'https://randomuser.me/api/portraits/men/31.jpg', userName:'Furkan', time:'11:22'} 
        // newMessage.message = messageText
        // setUserMessages([...userMessages,newMessage])

        if (messageText.length <= 1) {
            return;
        }

        setMessages([...messages, {
            date: new Date(),
            message: messageText.substring(0, 256),
            userId: "me"
        }]);

        call.sendChat(messageText);
        setMessageText("");
    }
    const messagesEndRef = React.useRef(null)


    const onKeyPress = (event) => {
        console.log(event)
        if (event.key === 'Enter') {
            sendButton()
            setMessageText('')

        }
    }

    const chatOnOff = () => {
        setS(!s)

        setUnreadMessageCount(0)
    }

    const drawingButtonsOnOff = () => {
        setShowDrawingButtons(!showDrawingButtons)
    }

    const drawerOnOff = () => {
        setDrawerEnabled(!drawerEnabled)
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const startDraw = (peer: Peer) => {
        console.log('startDraw')
        call.startAR(peer.socketId);
        // call.streamCanvas(canvasRef.current.canvas.canvas.drawing)
        setHasCanvas(true);
    }

    React.useEffect(() => {
        scrollToBottom()
        console.log('asdas')
    }, [messages]);

    const fullScreenPeer: string | null = callState?.ar?.to != null ? (callState.ar.to) :
        peers.length != 0 ? (selectedPeer ?? (peers.find(peer => peer.speaking)?.socketId ?? peers[0].socketId)) : null

    const arScreenPeer = callState?.ar?.from != null ? callState.ar.from : null

    React.useEffect(() => {
        console.log("Peer Update", fullScreenPeer);

        if (fullScreenPeer === "you") {
            if (fullscreenVideoRef.current.srcObject != localVideoRef.current.srcObject) {
                fullscreenVideoRef.current.srcObject = localVideoRef.current.srcObject
            }
        } else {
            const peer = peers.find(peer => peer.socketId === fullScreenPeer);
            if (peer && peer.stream != fullscreenVideoRef.current.srcObject) {
                fullscreenVideoRef.current.srcObject = peer.stream
            }
        }

        if (arScreenPeer) {
            if (arScreenPeer === "you") {
                if (arFullscreenVideoRef.current.srcObject != localVideoRef.current.srcObject) {
                    arFullscreenVideoRef.current.srcObject = localVideoRef.current.srcObject
                }
            } else {
                const peer = peers.find(peer => peer.socketId === arScreenPeer);
                if (peer && peer.stream != arFullscreenVideoRef.current.srcObject) {
                    arFullscreenVideoRef.current.srcObject = peer.stream
                }
            }
        }
    }, [fullScreenPeer, peers, arScreenPeer]);

    const MicSoundIcon = () => {

        return (
            isUserCallUnMuted ?
                <MicSharp color={'white'} /> :
                <MicOffSharp color={'white'} />
        )
    }

    React.useEffect(() => {
        if(!call) {
            return;
        }
        if (!canvasRef.current) {
            call.backToCamera();
            return;
        }

        if(!canvasRef.current.canvas.drawing) {
            return;
        }

        // call.streamCanvas(canvasRef.current.canvas.drawing)
    }, [canvasRef.current, call]);

    return (
        <div className="container">
            <div className="settings-model" style={showSettings ? { display: 'flex' } : { display: 'none' }}>
                <div onClick={() => setShowSettings(!showSettings)} className="settings-model" />


                <div className="settings-content">
                    <SettingsModal setSettings={(name: string, checkedParticipantNotification: boolean, checkedChatNotification: boolean) => {
                        setUsername(name);
                        setShowSettings(false);
                        setMuteMessageSound(checkedChatNotification)
                        setMuteJoinedRoomSound(checkedParticipantNotification)

                    }} userName={call?.userName} onClose={() => setShowSettings(false)} />
                </div>
            </div>
            <div className="content-container">
                <div className="video-chat-container">
                    <div className="video-main">
                        <div className="video-main-container">
                        { !hasCanvas ? 
                            <video ref={fullscreenVideoRef} autoPlay playsInline muted ></video> : null
                        }

                            {
                                callState?.ar?.from != null ?
                                    <video ref={arFullscreenVideoRef} style={{ mixBlendMode: 'overlay' }} className="video-main-container" autoPlay playsInline muted ></video>
                                    : null
                            }
                            { hasCanvas ? 
                            <div className={"video-main-container"}>
                                <CanvasDraw ref={canvasRef} brushRadius={4} lazyRadius={3} canvasHeight="100%" canvasWidth="100%" hideGrid={true} brushColor={`rgba(${colorRGBA.r}, ${colorRGBA.g}, ${colorRGBA.b}, ${colorRGBA.a})`} backgroundColor={"clear"} />
                            </div> : null}
                        </div>

                        {/* {callState?.ar?.from != null ? <div className="record-controls" onClick={() => call.startAR(null)}>AR</div> : null} */}



                        {/* <div  style={{  display: 'block',
                                position: 'absolute',
                                top: 10,
                                left: 10,
                                right: 0,
                                }}> 
                                <SketchExample changeColor={changeColor} />
                      
                              </div> */}
                        <div className="user-controls" >

                            <div className="small-window-container image" style={{}}>
                                <video ref={localVideoRef} autoPlay playsInline muted></video>
                                <div className="small-window-button small-window-button-left" onClick={() => { call.switchCamera(); }}>

                                    <CameraReverse color={'white'} />
                                </div>
                                <p className="small-window-title">{username ?? 'Anonymous'}</p>
                            </div>
                            {
                                peers.map(peer => {
                                    return (<PeerView arEnabled={callState ? callState.ar === null : false} drawerStatus={drawerEnabled} changeDrawerStatus={() => startDraw(peer)} selectedPeer={selectedPeer} onPress={() => {
                                        if (selectedPeer === peer.socketId) {
                                            setSelectedPeer(null);
                                        } else {
                                            setSelectedPeer(peer.socketId);
                                        }
                                    }} key={peer.socketId} call={call} className={peer.speaking ? "active" : ""} peer={peer} />)
                                })
                            }
                        </div>

                        {/* 
                        <div className="volume-controls">
                            <div className="blur-square-buttons">

                                <VolumeMute color={'white'} />
                            </div>
                        </div> */}
                        <div className="controls-controls">

                            {!callState?.isScreenSharing && !callState?.ar ? <div className="blur-circle-buttons" onClick={() => {
                                call.getScreenShare();
                            }} >
                                <DesktopSharp color={'white'} />
                            </div> : null}

                            <div className="blur-circle-buttons" onClick={() => {
                                setIsUserCallUnMuted(call?.toggleMute())
                            }}>
                                <MicSoundIcon />
                            </div>
                            <div className="blur-square-buttons red" onClick={() => {
                                window.location.href = "https://support3e.appac.io/";
                            }}>
                                <CallSharp color={'white'} style={{ transform: "rotate(134deg)" }} />
                            </div>

                            <div className="blur-circle-buttons" onClick={() => {
                                setShowUserVideo(call?.toggleVideo())
                            }}>
                                {
                                    showUserVideo ?
                                        <Videocam color={'white'} /> :
                                        <VideocamOff color={'white'} />

                                }

                            </div>
                            <div className="blur-circle-buttons" onClick={() => setShowSettings(!showSettings)}>
                                <SettingsSharp color={'white'} />
                            </div>



                        </div>


                        <div className="chat-controls">
                            <div style={{ zIndex: 100 }} className="blur-square-buttons" onClick={chatOnOff}>

                                <ChatbubblesSharp color={'white'} />
                                {unreadMessageCount != 0 ? <span className='badge-top'>{unreadMessageCount}</span> : null}

                            </div>
                                { hasCanvas ?
                            <><div className="blur-square-buttons" onClick={drawingButtonsOnOff}>
                                <FaPaintBrush color={`rgba(${colorRGBA.r}, ${colorRGBA.g}, ${colorRGBA.b}, ${colorRGBA.a})`} size={20} />
                                <span className='badge-bottom'>
                                    {showDrawingButtons ? <FaArrowUp color={'black'} size={10} /> : <FaArrowDown color={'black'} size={10} />}
                                </span>
                            </div>
                            {showDrawingButtons ?
                                <>
                                    <div className="blur-square-buttons" style={{ zIndex: 999 }}>

                                        <SketchExample changeColor={changeColor} />
                                    </div>
                                    <div style={{ zIndex: 100 }} className="blur-square-buttons" onClick={() => {
                                        canvasRef.current.undo()
                                    }}>

                                        <ArrowUndoSharp color={'white'} />
                                    </div>
                                </> : null}
                                </>
: null }
                            {callState?.ar?.from != null ? <div className="blur-square-buttons" onClick={() => call.startAR(null)}>AR</div> : null}


                        </div>

                        { /* Record Button */}
                        { /* Volume Button */}
                        { /* Call Controls */}
                        { /* Show Chat Button */}
                        { /* Remote Bubbles */}
                        { /* Active video display */}
                    </div>
                    {/* <div className="video-container">
                        {
                            peers.map(peer => {
                                return (<PeerView key={peer.socketId} className={peer.speaking ? "active" : ""} peer={peer} />)
                            })
                        }
                    </div> */ }
                    {/* <LocalCall call={call} /> */}
                </div>

                <div className={classNames(["side-menu", { "active": s }])} >
                    <div style={{ paddingBottom: 90 }} className={"chat-container"}>

                        <div className='side-menu-top-left' >

                            <CloseCircleOutline color={'black'} width={'28px'} height={'28px'} onClick={() => { chatOnOff() }} />
                        </div>

                        {messages.map(userMessage =>
                        (userMessage.userId == 'server' ?
                            <p key={`${userMessage.date}`} style={{ margin: 4, color: 'gray', textAlign: 'center', marginTop: 8, fontSize: 12 }}>{userMessage.message}</p>
                            :
                            (userMessage.userId == 'me' ?
                                <UserChatMessage key={`${userMessage.date}`} imageLink={`https://avatars.dicebear.com/api/jdenticon/${userMessage.userId}.svg`} message={userMessage.message} userName={"Ben"} time={`${userMessage.date}`} /> :
                                <ParticipantChatMessage key={`${userMessage.date}`} imageLink={`https://avatars.dicebear.com/api/jdenticon/${userMessage.userId}.svg`} message={userMessage.message} userName={peers.find(p => p.socketId === userMessage.userId)?.name ?? "Anonymous"} time={`${userMessage.date}`} />
                            )
                        ))}
                        <div ref={messagesEndRef} />

                    </div>
                    {/* <div style={{position:'absolute',paddingLeft:'20px', bottom:'8.5%'}}>
                      <p style={{fontSize:13}}> Mehmet yazıyor... </p>
                    </div>
                     */}
                    <div className="chatBox shadow">
                        <div className="custom-chat-textbox">
                            <input type="text" onKeyDown={e => onKeyPress(e)} className="custom-chat-input" placeholder="Mesajınızı Giriniz" value={messageText} onChange={e => setMessageText(e.target.value)} />
                            <input type="file" hidden name="file" id="file" className="inputfile" />

                            <label htmlFor="file" className="custom-chat-botton"><Attach color="#9ca3ad"></Attach></label>

                        </div>
                        <button onClick={sendButton} className="custom-chat-send-button" type="submit">

                            <SendSharp color="white" />
                        </button>

                    </div>

                </div>


            </div>
        </div>
    )
}

CallManager.propTypes = {
    roomId: PropTypes.string.isRequired,

}